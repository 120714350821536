
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonNote,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { reactive } from "@vue/reactivity";

import UserSelect from "@/components/selects/UserSelect.vue";
import { createJob } from "@/db";
import { nameToID } from "@/helpers";
import router from "@/router";
import store from "@/store";

export default {
  name: "Select Date",
  props: {
    customerID: String,
  },
  setup(props: any) {
    const state = reactive({
      customerID: props.customerID == "new" ? "" : props.customerID,
      jobName: "",
    });

    const create = async () => {
      // Create a job with an id
      if (state.customerID && state.jobName) {
        await createJob(state.jobName, state.customerID);
        router.replace({
          name: "Job",
          params: {
            userID: state.customerID,
            id: nameToID(state.jobName),
          },
        });
      } else throw Error("Please select a customer and job name.");
    };

    return {
      store,
      router,
      state,
      create,
    };
  },
  components: {
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonTitle,
    IonBackButton,
    UserSelect,
    IonButton,
    IonNote,
    IonInput,
    IonItem,
    IonCard,
  },
};
